<template>
  <AudioSearch
    :icon="icon"
    :label="label"
    :title="title"
    :route="route"
    :status="status"
    :show-revisor="false"
  />
</template>

<script>
import menuConfig from '@/components/menuConfig'
import AudioSearch from '@/views/Dashboard/AudioSearch'
import {
  CODE_AUDIOS_APPROVED,
  ROUTE_AUDIOS_APPROVED
} from '@/constants'

export default {
  components: {
    AudioSearch
  },

  data () {
    const status = CODE_AUDIOS_APPROVED
    const { icon, title, label, route } = menuConfig.find(({ route }) => route === ROUTE_AUDIOS_APPROVED)
    return { icon, title, label, route, status }
  }
}
</script>
